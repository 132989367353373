import React from 'react'
import './Footer.css'

const Footer = () => (
  <footer className='footer'>
    Edgar Pino
  </footer>
)

export default Footer
